import "../styles/index.css";
// import { throttle, debounce } from "./helpers";
// import axios from "axios";
// import Animation from "./animation";
// import textImg from './text'
// const url = require("./text.png");

console.log("app.js");

window.addEventListener("load", function() {
  console.log("load");
  animationContainer.classList.add("animation-loaded");
});

// jQuery(document).ready(function() {
//   console.log("jquery ready");

//   // post content,
//   // if successful, save to cookie, display as gray

//   var list = document.querySelectorAll(".swiper");
//   for (var i = 0; i < list.length; i++) {
//     var isOdd = i % 2 === 1;

//     var randomPart = Math.floor(Math.random() * 7) * 1000;
//     var speed = 10000 + randomPart;

//     console.log("swiper " + i);

//     var swiper = new Swiper(".swiper" + i, {
//       // Optional parameters
//       direction: "horizontal",
//       loop: true,
//       speed: speed,
//       slidesPerView: "auto",

//       //   navigation: {
//       //     nextEl: ".swiper-button-next",
//       //     prevEl: ".swiper-button-prev"
//       //   },
//       autoplay: {
//         delay: 1,
//         disableOnInteraction: false,
//         reverseDirection: isOdd
//       },
//       // pagination: {
//       //   el: ".swiper-pagination",
//       //   clickable: true
//       // },
//       freeMode: true

//       // And if we need scrollbar
//       //   scrollbar: {
//       //     el: ".swiper-scrollbar"
//       //   }
//     });
//   }

//   $(document).on("click", ".hamburger-checkbox", function(e) {
//     if (e.target.checked) {
//       $("body").addClass("show-menu");
//     } else {
//       $("body").removeClass("show-menu");
//     }
//   });

//   $(document).on("click", "a.AddButton, a.AddButton--method", function(e) {
//     $(".CDForm__result-error").html("");
//     $(".CDForm__result").html("");

//     $(".CDForm__row").hide();
//     $(".CDForm__row input").val("");

//     $(document.body).addClass("show-form");
//     const rowType = $(this).data("type");
//     const method = $(this).data("method");

//     console.log("rowType:", rowType);
//     console.log("method:", method);

//     $(
//       ".CDForm__row--" +
//         rowType +
//         " #name-url, .CDForm__row--" +
//         rowType +
//         " #project-url"
//     ).val("https://");

//     $(".CDForm__row--" + rowType).show();

//     $("input[type='submit']").prop("disabled", true);

//     const headingData = $(this).data("heading");
//     const descriptionData = $(this).data("description");

//     $(".CDForm__heading").html(headingData);
//     $(".CDForm__description").html(descriptionData);

//     setTimeout(() => {
//       $("input.input-type").val(rowType);
//       $("input.input-method").val(method);
//       $("input[type='submit']").prop("disabled", false);
//     }, 500);
//   });

//   $(document).on("click", ".CDForm__backdrop", function() {
//     $(document.body).removeClass("show-form");
//   });

//   $(document).on("click", ".CDForm", function(e) {
//     e.stopPropagation();
//   });

//   $(document).on("submit", ".CDForm__form", function(event) {
//     // $('.registration-form').submit(function (event) {
//     console.log("submit");

//     var isCaptchaForm = event.target.classList.contains(
//       "CDForm__form--captcha"
//     );

//     var formAction = event.target.getAttribute("action");

//     $(".CDForm__result-error").html();
//     $(".CDForm__result").html();
//     event.preventDefault();
//     var formData = new FormData($(this)[0]);

//     $(".CDForm input[type='submit']").prop("disabled", true);

//     axios
//       .post(formAction, formData)
//       // .post("/wp-json/content/add", formData)
//       .then(function(response) {
//         $(".CDForm__result-error").html("");
//         $(".CDForm__result").html("");

//         var message = response.data.data.message;
//         var success = response.data.data.ok;

//         if (!success) {
//           console.log("no success", message, response);

//           $(".CDForm__result-error").html(message);
//           $(".CDForm input[type='submit']").removeAttr("disabled");
//         } else {
//           $(".CDForm__result").html(message);
//           // TODO clear form? it reloads anyway

//           if (isCaptchaForm) {
//             $(".captcha-is-not-verified")
//               .addClass("captcha-is-verified")
//               .removeClass("captcha-is-not-verified");
//           }

//           if (isCaptchaForm) {
//             setTimeout(function() {
//               $(".CDForm__result-error").html("");
//               $(".CDForm__result").html("");
//             }, 3000);
//           }

//           setTimeout(function() {
//             $(".CDForm input[type='submit']").removeAttr("disabled");

//             if (!isCaptchaForm) {
//               window.location.reload();
//             }
//             // window.location.replace('/')
//           }, 1000);
//         }
//       })
//       .catch(function(error) {
//         $(".CDForm__result-error").html(error.response.data.data["message"]);
//         setTimeout(function() {
//           $(".CDForm__result-error").html("");
//           $(".CDForm__result").html("");
//         }, 3000);
//         $(".CDForm input[type='submit']").removeAttr("disabled");
//       });
//   });
// });
